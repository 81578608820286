import {
    Container,
    Row,
    Col
} from 'reactstrap';
import styles from './styles.module.scss';
import clsx from "classnames";
import PropTypes from "prop-types";
import Form from "@/components/Shared/Form";
import EditableImage from "@/components/EditableImage";
import EditableText from "@/components/EditableText";
import JobSearchInput from "@/components/JobSearchInput";
import LightBlulbIcon from "/public/site-assets/svg/light-blub.svg";
import StarIcon from "/public/site-assets/svg/star.svg";
import PencilIcon from "/public/site-assets/svg/pencil.svg";
import RulerIcon from "/public/site-assets/svg/ruler.svg";
import PotIcon from "/public/site-assets/svg/pot.svg";
import LeafIcon from "/public/site-assets/svg/leaf.svg";
import BrushIcon from "/public/site-assets/svg/brush.svg";
import OverlayDots from "/public/site-assets/svg/overlay-dots.svg";
import DottedLines from "/public/site-assets/svg/main-banner-dotted-lines.svg";

import { motion } from 'framer-motion';
import React, { useRef, useState, useEffect } from 'react';

import dynamic from 'next/dynamic';
const Lottie = dynamic(() => import("lottie-react"), { ssr: false });

import paper_plane from "./paper-plane.json";

export default function MainBanner({
    className,
    global,
    pathPrefix,
    heading,
    heading_2,
    heading_tag,
    subtitle,
    subtitle_tag,
    word_content = ["Education", "Every Child", "Your Career"],
}) {
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const mobileDevice = window.innerWidth <= 991; // Adjust threshold for mobile devices
            setIsMobile(mobileDevice);
        };

        handleResize(); // Call initially
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1 // Adjust threshold as needed, make it smaller for more sensitive detection
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        observer.observe(sectionRef.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    const [wordIndex, setWordIndex] = useState(0);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setWordIndex((prevIndex) => (prevIndex + 1) % word_content.length);
            setAnimate(true);
            setTimeout(() => {
                setAnimate(false);
            }, 500);
        }, 2000);

        return () => clearInterval(intervalId);
    }, [word_content]);

    return (
        <>
            <section ref={sectionRef} className={clsx(className, styles.root)}>
                <span className='position-absolute dotted-lines'>
                    {!isMobile && (
                        <Lottie animationData={paper_plane} loop={false} />
                    )}
                </span>
                <Container className='position-relative'>
                    <motion.div className='floating-icon' initial={{ opacity: 0 }} animate={isVisible ? { opacity: 1 } : {}} transition={{ duration: 1.5, delay: 0.3 }}><LightBlulbIcon /></motion.div>
                    <motion.div className='floating-icon' initial={{ opacity: 0 }} animate={isVisible ? { opacity: 1 } : {}} transition={{ duration: 1.5, delay: 1 }}><PencilIcon /></motion.div>
                    <motion.div className='floating-icon' initial={{ opacity: 0 }} animate={isVisible ? { opacity: 1 } : {}} transition={{ duration: 1.5, delay: 1.3 }}><RulerIcon /></motion.div>
                    <motion.div className='floating-icon' initial={{ opacity: 0 }} animate={isVisible ? { opacity: 1 } : {}} transition={{ duration: 1.5, delay: 2 }}><PotIcon /></motion.div>
                    <motion.div className='floating-icon' initial={{ opacity: 0 }} animate={isVisible ? { opacity: 1 } : {}} transition={{ duration: 1.5, delay: 2.3 }}><LeafIcon /></motion.div>
                    <motion.div className='floating-icon' initial={{ opacity: 0 }} animate={isVisible ? { opacity: 1 } : {}} transition={{ duration: 1.5, delay: 3 }}><BrushIcon /></motion.div>
                    <motion.div className='floating-icon' initial={{ opacity: 0, y: 100 }} animate={isVisible ? { opacity: 1, y: 0 } : {}} transition={{ duration: 1.5, delay: 0.3 }}><OverlayDots /></motion.div>
                    <motion.div className='floating-icon' initial={{ opacity: 0, y: -100 }} animate={isVisible ? { opacity: 1, y: 0 } : {}} transition={{ duration: 1.5, delay: 0.3 }}><OverlayDots /></motion.div>

                    <div className='wrapper'>
                        <h3 className='heading mb-4'>
                            {heading && (
                                <EditableText
                                    content={heading}
                                    path={`${pathPrefix}.heading.1`}
                                    global={global}
                                    tag={`span`}
                                    className={`d-block w-100`}
                                />
                            )}
                            {!isMobile ?
                                <span className={`${animate ? 'fade-up-animation' : ''} w-100 animate-text`}>{word_content[wordIndex]}</span>
                                :
                                <span className={`w-100 animate-text`}>Education</span>
                            }

                            {heading_2 && (
                                <EditableText
                                    content={heading_2}
                                    path={`${pathPrefix}.heading.2`}
                                    global={global}
                                    tag={`span`}
                                    className={`d-block w-100`}
                                />
                            )}
                        </h3>
                        {subtitle && (
                            <div>
                                <EditableText
                                    content={subtitle}
                                    path={`${pathPrefix}.subtitle`}
                                    global={global}
                                    tag={`${subtitle_tag}`}
                                    className={`subtitle`}
                                />
                            </div>
                        )}
                        <div className='mt-4'>
                            <JobSearchInput
                                locationInput={true}
                                radiusInput={false}
                            />
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

MainBanner.defaultProps = {
    pathPrefix: "",
    className: "",
    subtitle_tag: "h1",
    heading_tag: "div"
};

MainBanner.propTypes = {
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
    subtitle_tag: PropTypes.string,
    heading_tag: PropTypes.string
};
